import React from 'react';
import {useRessourceQuery} from '@airstudio/ui/dist/hooks/ressourceQuery';
import {useParamsKey} from '@airstudio/ui/dist/hooks/paramsKey';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Selectors } from '@airstudio/state';
import {Helmet} from 'react-helmet';

import {Kudo as KudoComponent} from '../components/Kudo';
import './Post.scss';

export const Post: React.FC = () => {

  const {isAuthenticated} = useSelector(Selectors.Session.state);
  const id = useParamsKey('id');
  const { ressource } = useRessourceQuery<{
    id: string,
    fromUser: {label: string, id: string},
    toUsers: Array<{label: string, id: string}>,
  }>({path: '/kudos', id});

  if (!ressource) {
    return (<div/>);
  }

  return (
    <div className="wrapper wrapper__kudo">

      <Helmet>
        <title>{ressource.fromUser.label} and {ressource.toUsers[0].label} are greateful &mdash; Nijis</title>
      </Helmet>

      {!isAuthenticated && (
        <div className="intro">
          <p>
            <img src="/logo.svg" alt="Nijis Logo" className="logo" />
          </p>
          <p className="lead">
            People in your community like <span style={{textTransform: 'capitalize'}}>{ressource.fromUser.label}</span><br/>
            uses Nijis to share their gratefulness.
          </p>

          <p className="lead">
            <Link to="/">
              Join the conversation
            </Link>
          </p>
        </div>
      )}

      {isAuthenticated && (
        <h3>
          <Link to="/">
            View all posts
          </Link>
        </h3>
      )}

      <KudoComponent kudoId={id} full />
    </div>
  );
};
