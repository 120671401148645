import React from 'react';
import {Avatar} from 'rsuite';

import './style.scss';

export interface IUserAvatarProps {
  userId?: string;
  userLabel?: string;
  offset?: number;
  small?: boolean;
}

export const UserAvatar: React.FC<IUserAvatarProps> = ({userId, userLabel, small = false, offset = 0}) => {

  /*
    const speaker = (
      <Popover title="Raff Paquin" visible>
        <p>
          Montreal, Canada
        </p>
        <ButtonToolbar>
          <Button appearance="link">
            View Profile
          </Button>
          <Button appearance="primary">
            Follow &nbsp;
            <Icon icon="plus"  />
          </Button>
        </ButtonToolbar>
      </Popover>
    );
  */

  const src = userId && `https://kudos.apis.plyk.io/users/${userId}/images`;
  const alt = userLabel && userLabel[0];

  return (
    <>
      {/*<Whisper trigger="click" placement="top" speaker={speaker}>*/}
        <div className="userAvatar" style={{left: -10 * offset}}>
          <Avatar
            circle
            size={small ? 'md' : 'lg'}
            className="image"
            src={src}
            alt={alt}
          />
          {!small && (
            <div className="label">
              {userLabel || ''}
            </div>
          )}
        </div>
      {/*</Whisper>*/}
    </>
  );
};
