import React from 'react';

import { Feed } from '../components/Feed';

export const Home: React.FC = () => {
  return (
    <div className="wrapper">
      <Feed />
    </div>
  );
};
