import React from 'react';
import { Container, Content, Header, Footer, Loader } from 'rsuite';
import { Router, AppWrapper } from '@airstudio/ui';

import * as Pages from '../pages';
import {Menu} from './Menu';
import {AuthScreen} from './AuthScreen';

const RootNoUser: React.FC = () => {
  return (
    <Router.Switch>
      <Router.Route path="/posts/:id" component={Pages.Post} />
      <Router.Route component={AuthScreen} />
    </Router.Switch>
  );
};

const RootLoading: React.FC = () => {
  return (
    <div style={{textAlign: 'center', padding: 200}}>
      <Loader size="lg" />
    </div>
  );
};

export const Root: React.FC = () => {
  return (
    <AppWrapper authentification={RootNoUser} loading={RootLoading}>
      <Container>
        <Header>
          <Menu />
        </Header>

        <Content>
          <Router.Switch>
            <Router.Route path="/posts/:id" component={Pages.Post} />
            <Router.Route path="/" component={Pages.Home} />
            <Router.Route component={Pages.E404} />
          </Router.Switch>
        </Content>

        <Footer>
          {/*This is the footer*/}
        </Footer>
      </Container>
    </AppWrapper>
  );
};
