import {Extensions, Bus, Operations } from '@airstudio/state';
import Cookies from 'js-cookie';

export class CustomAuth extends Extensions.Abstract {

  key = 'custom-auth';
  URL_KEY = 'c';
  COOKIE_KEY = 'airboat.jwt';

  constructor() {
    super();

    Bus.on(Bus.ON_CONFIG_READY, async () => {

      const jwtProviders: Array<() => Promise<string | null>> = [
        async () => new URLSearchParams(window.location.search).get(this.URL_KEY),
        async () => Cookies.get(this.COOKIE_KEY) || null,
      ];

      for (const i in jwtProviders) {
        if (jwtProviders.hasOwnProperty(i)) {
          try {
            const jwt = await jwtProviders[i]();
            if (jwt) {
              const isSuccess = await Operations.Session.login(this.dispatch)(jwt);
              if (isSuccess) {
                Cookies.set(this.COOKIE_KEY, jwt);
                return;
              }
            }
          // tslint:disable-next-line: no-empty
          } catch (e) {}
        }
      }

      // Trigger a signout
      await Operations.Session.logout(this.dispatch)();

    });

    Bus.on(Bus.ON_USER_LOGOUT, async () => {
     Cookies.remove(this.COOKIE_KEY);
    });
  }
}
