import React from 'react';
import {useRessourceListQuery} from '@airstudio/ui/dist/hooks/ressourceListQuery';
import { Placeholder } from 'rsuite';

import {Kudo} from '../Kudo';
import {PostInput} from '../PostInput';
import {Onboarding} from '../Onboarding';
import './style.scss';
import { IKudo } from '../../types';

export const Feed: React.FC = () => {

  const {
    isProcessing,
    ressources,
  } = useRessourceListQuery<IKudo>({ path: '/kudos' });

  const feedSort = (a: IKudo, b: IKudo) => {
    const createdAtA = a.createdAt.toUpperCase();
    const createdAtB = b.createdAt.toUpperCase();
    if (createdAtA < createdAtB) {
      return 1;
    }
    if (createdAtA > createdAtB) {
      return -1;
    }

    return 0;
  };

  return (
    <div className="feed">

      <PostInput />

      <h4>Recent Posts</h4>

      {isProcessing && (
        [1, 2, 3].map((i) => <Kudo kudoId="fake-id-loading" key={i}><Placeholder.Paragraph /></Kudo>)
      )}

      {!isProcessing && (
        ressources?.sort(feedSort).map(({id}) => (
          <Kudo kudoId={id} key={id}/>
        ))
      )}

      <Onboarding />
    </div>
  );
};
