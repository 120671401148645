import React from 'react';
import {Icon, FlexboxGrid, Divider, Placeholder} from 'rsuite';
import { useSelector } from 'react-redux';
import { Selectors } from '@airstudio/state';
import { Link } from 'react-router-dom';

import {UserAvatar} from '../UserAvatar';
import {KudoComments} from '../KudoComments';
import {Date} from '../Date';
import './style.scss';
import {IKudo} from '../../types';

export interface IKudoProps {
  kudoId: string;
  full?: boolean;
}

/*const getReactionCount = (kudo: IKudo, reactionType: string): number => {
  const reactionList = kudo.reactionCount || [];
  const validReaction = reactionList.filter(({reaction}) => reaction === reactionType);
  if (validReaction.length === 0) {
    return 0;
  }

  return validReaction[0].count;
};*/

export const Kudo: React.FC<IKudoProps> = ({kudoId, full = false}) => {

  const kudo = useSelector(Selectors.Entities.getItemById<IKudo>('/kudos', kudoId));
  const {isProcessing} = useSelector(Selectors.Entities.stateByItemId('/kudos', kudoId));

  if (!kudo) {
    return <KudoLoading />;
  }

  // const likeCount = getReactionCount(kudo, 'love');

  return (

    <div className={`card kudo kudo-${kudo.id}`}>

      <FlexboxGrid className="avatars" justify="start" align="middle">
        <FlexboxGrid.Item>
          <UserAvatar userId={kudo.fromUser.id} userLabel={kudo.fromUser.label} /><br/>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item>
          <Icon icon="angle-right" size="2x" className="arrow"  />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item>
          {kudo.toUsers.map((user, offset) => (
            <UserAvatar offset={offset} key={user.id} userId={user.id} userLabel={user.label} />
          ))}
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <div className="content">
        {kudo.content}
      </div>

      <Divider />

      <FlexboxGrid justify="space-between">
        <FlexboxGrid.Item>
          {/*
            <Link to={`/posts/${kudo.id}`}>
              {
                likeCount > 0
                  ? `${likeCount} likes`
                  : `Like`
              }
            </Link>
            &nbsp;&nbsp;&nbsp;
          */}
          <Link to={`/posts/${kudo.id}?comments`}>
            {
              kudo.commentCount && kudo.commentCount > 0
                ? `${kudo.commentCount} comments`
                : `Add comment`
            }
          </Link>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item>
          <Link to={`/posts/${kudo.id}`} style={{cursor: 'pointer'}}>
            <Date value={kudo.createdAt} />
          </Link>
        </FlexboxGrid.Item>
      </FlexboxGrid>

      {full && <KudoComments comments={kudo.comments} isProcessing={isProcessing} />}

    </div>
  );
};

export const KudoLoading: React.FC = () => {
  return (
    <div className={`card kudo kudo-loading`}>

      <FlexboxGrid className="avatars" justify="start" align="middle">
        <FlexboxGrid.Item>
          <UserAvatar />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item>
          <Icon icon="angle-right" size="2x" className="arrow"  />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item>
          <UserAvatar />
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <div className="content">
        <Placeholder.Paragraph  rows={2} />
      </div>

      <Divider />

      <FlexboxGrid justify="space-between">
        <FlexboxGrid.Item />

        <FlexboxGrid.Item>
          <Placeholder.Paragraph rows={1} />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
  );
};
