import React, { useState } from 'react';
import {FlexboxGrid, Input, Placeholder} from 'rsuite';
import {useParamsKey} from '@airstudio/ui/dist/hooks/paramsKey';
import { useWorkspace } from '@airstudio/ui/dist/hooks/workspace';
import {Operations, Selectors, TrackEvent} from '@airstudio/state';
import { useDispatch, useSelector } from 'react-redux';
import {orderBy} from 'lodash';

import './style.scss';
import { IKudoComment } from '../../types';
import {UserAvatar} from '../UserAvatar';
import {Date} from '../Date';

export interface IKudoCommentsProps {
  comments?: IKudoComment[];
  isProcessing?: boolean;
}

export const KudoComments: React.FC<IKudoCommentsProps> = ({comments = [], isProcessing = false}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState('');
  const customSubmit = Operations.Entities.custom(useDispatch());
  const id = useParamsKey('id');
  const workspaceId = useWorkspace();
  const user = useSelector(Selectors.Me.get) as {id: string, firstname: string};

  const handleSubmit = async () => {
    if (content.length === 0) {
      return;
    }

    TrackEvent('newComment.request');
    setIsLoading(true);

    const isSuccess = await customSubmit({
      workspaceId,
      request: {
        method: 'POST',
        url: `/kudos/${id}/comments`,
        data: {
          item: { content },
        },
      },
      statePath: '/kudos:comments',
      reducers: {
        entitiesByIdReducer: (state, wid, data) => {
          const key = `${workspaceId}:/kudos:${id}`;
          const kudo = state.get(key) as {commentCount?: number, comments?: object[]};
          if (!kudo || !data) {
            return state;
          }

          const comment = (data as any).comment;

          kudo.commentCount = kudo.commentCount
            ? kudo.commentCount + 1
            : 1;

          if (!kudo.comments) {
            kudo.comments = [];
          }
          kudo.comments.push({
            ...comment, userFrom: {id: user.id, label: user.firstname},
          });

          return state.set(key, kudo);
        },
      },
    });

    setIsLoading(false);

    if (isSuccess) {
      setContent('');
      TrackEvent('newComment.success');
    }
  };

  const sortedComments = orderBy(comments, 'createdAt', 'desc');

  return (
    <div className="kudoComments">

      <div>
        <Input
          className="input"
          placeholder="Write a comment"
          value={content}
          onChange={setContent}
          onPressEnter={handleSubmit}
          disabled={isProcessing || isLoading}
        />
      </div>

     {sortedComments.map((comment) => (
       <div className="comment" key={comment.id}>
        <FlexboxGrid className="avatars" justify="start" align="middle">
          <FlexboxGrid.Item className="avatar">
            <UserAvatar small userId={comment.userFrom.id} />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item>
            <span className="metadata">
              <b className="userLabel">{comment.userFrom.label}</b>
              <Date value={comment.createdAt} />
            </span>
            <br />

            <div className="content">
              {comment.content}
            </div>
          </FlexboxGrid.Item>

        </FlexboxGrid>
       </div>
     ))}

      {isProcessing && (
        <>
          <Placeholder.Paragraph rows={2} graph="circle" />
          <Placeholder.Paragraph rows={2} graph="circle" />
        </>
      )}

    </div>
  );
};
