import React, {useState} from 'react';
import { Input, Button, Message } from 'rsuite';
import { Api } from '@airstudio/foundations';
import { useSelector } from 'react-redux';
import { Selectors, TrackEvent } from '@airstudio/state';

import './style.scss';

export const AuthScreen: React.FC = () => {

  const [email, setEmail] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const {isProcessing: isLoadingCredentials} = useSelector(Selectors.Session.state);

  const handleSubmit = async () => {
    setIsProcessing(true);

    TrackEvent('auth.request');

    await Api.client.post('/users', {
      item: { email },
    });

    setIsConfirmationVisible(true);
    TrackEvent('auth.success');
  };

  return (
    <div className="wrapper">
      {!isLoadingCredentials && (
        <div className="card card__auth">
          <p>
            <img src="/logo.svg" alt="Nijis Logo" />
          </p>
          <p className="lead">
            People in your community uses<br/>
            Nijis to share their gratefulness.
          </p>

          <p>
            To join the conversation for free,
            enter your email address bellow.
          </p>

          {!isConfirmationVisible && (

            <div className="authForm">
              <p>
                <Input
                  type="email"
                  placeholder="example@company.com"
                  size="lg"
                  value={email}
                  onChange={setEmail}
                  disabled={isProcessing}
                  onPressEnter={handleSubmit}
                />
              </p>

              <p>
                <Button
                  block
                  appearance="primary"
                  loading={isProcessing}
                  disabled={email.length === 0}
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              </p>
            </div>
            )}

            {isConfirmationVisible && (
              <div className="confirmationMessage">
                <Message
                  showIcon
                  type="success"
                  title="Please check your email"
                  description={`An email containing a unique link has been sent to ${email}. This link expires in 20 minutes.`}
                />
              </div>
            )}
        </div>
      )}
    </div>
  );
};
