import React, { useState } from 'react';
import { Icon, ButtonToolbar, IconButton, FlexboxGrid } from 'rsuite';
import { useSelector, useDispatch } from 'react-redux';
import { Selectors, Operations } from '@airstudio/state';
import { UserEditor } from '@airstudio/ui';
import { Link } from 'react-router-dom';

import './style.scss';

export const Menu: React.FC = () => {

  const { isAuthenticated } = useSelector(Selectors.Session.state);
  const dispatchLogout = Operations.Session.logout(useDispatch());
  const [isUserEditorVisible, setUserEditorVisible] = useState(false);

  return (
    <div>
      {isAuthenticated && (
        <>
          <UserEditor isVisible={isUserEditorVisible} setIsVisible={setUserEditorVisible} />
        </>
      )}

      <div className="navbar-container">
        <div className="navbar">
          <FlexboxGrid justify="space-between" align="middle">
            <FlexboxGrid.Item>
              <Link to="/">
                <img src="/logo.svg" alt=""/>
              </Link>
            </FlexboxGrid.Item>

            <FlexboxGrid.Item>
              <ButtonToolbar>
                <IconButton icon={<Icon icon="user-times" />} circle size="lg"  onClick={() => dispatchLogout()} />
              </ButtonToolbar>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </div>
      </div>
    </div>
  );
};
