import React from 'react';
import {Whisper, Tooltip} from 'rsuite';
import moment from 'moment';

export const Date: React.FC<{value: string}> = ({value}) => {

  const speaker = (
    <Tooltip>
      {moment(value).format('LLL')}
    </Tooltip>
  );

  return (
    <Whisper trigger="hover" speaker={speaker} placement="top">
      <span>{moment(value).fromNow()}</span>
    </Whisper>
  );
};
