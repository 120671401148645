import React, {useState} from 'react';
import {Input, Button, Modal, Tag, TagGroup, Message} from 'rsuite';
import { useDispatch, useSelector } from 'react-redux';
import { Operations, Selectors, TrackEvent } from '@airstudio/state';
import { useWorkspace } from '@airstudio/ui/dist/hooks/workspace';

import './style.scss';

export const PostInput: React.FC = ({children}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [submitRetryCount, setSubmitRetryCount] = useState(0);
  const [userList, setUserList] = useState<Array<{key: string, keyType: string, label: string}>>([]);
  const [postContent, setPostContent] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const currentUser = useSelector(Selectors.Me.get) as {firstname: string};

  const { isItemProcessing, itemErrorMessage } = useSelector(Selectors.Entities.state('/kudos'));

  const isUserTagDisabled = isItemProcessing || postContent.length === 0 || userList.length > 5;
  const isSumbitDisabled = isItemProcessing || userList.length === 0;
  const isTextareaDisabled = isItemProcessing;

  const dispatch = useDispatch();
  const workspaceId = useWorkspace();

  const handleFocus = () => {
    setIsFocused(true);

    TrackEvent('newPost.enter');
  };

  const handleBlur = () => {
    if (postContent.length > 0) { return; }
    setIsFocused(false);
    setUserList([]);
    setSubmitRetryCount(0);
    setUserEmail('');

    TrackEvent('newPost.exit');
  };

  const handleAddEmail = () => {
    TrackEvent('newPost.addEmail');

    setUserList([...userList, {
      key: userEmail,
      keyType: 'email',
      label: userEmail,
    }]);
    setIsEmailModalVisible(false);
    setUserEmail('');
  };

  const handleSubmit = async () => {

    TrackEvent('newPost.request');

    const saveEntities = Operations.Entities.save(dispatch);
    const isSuccess = await saveEntities(workspaceId, '/kudos', {
      content: postContent,
      toUserKey: userList.map((user) => ({
        email: user.keyType === 'email' ? user.key : undefined,
        phone: user.keyType === 'phone' ? user.key : undefined,
      })),
    }).finally(() => setSubmitRetryCount(submitRetryCount + 1));

    if (isSuccess) {
      setUserEmail('');
      setUserList([]);
      setIsFocused(false);
      setPostContent('');
      setSubmitRetryCount(0);
      TrackEvent('newPost.success');
    }
  };

  const emailModal = (
    <Modal show={isEmailModalVisible} onHide={() => setIsEmailModalVisible(false)}>
      <Modal.Header>
        <Modal.Title>Tag a friend by email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Input
          name="friend-email"
          autoFocus
          autoComplete="off"
          type="email"
          placeholder="What are you grateful for?"
          value={userEmail}
          onChange={setUserEmail}
          style={{fontSize: 16}}
          onPressEnter={handleAddEmail}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button  onClick={handleAddEmail} disabled={!userEmail} appearance="primary">
          Add to your post
        </Button>
      </Modal.Footer>
    </Modal>
    );

  const placeholder = currentUser && currentUser.firstname
    ? `What are you grateful for, ${currentUser.firstname}?`
    : 'What are you grateful for?';

  return (
    <div className={`postInput ${isFocused ? ' focused' : ''}`}>

      {emailModal}

      <div className="textbox">

        <textarea
          value={postContent}
          onChange={(e) => setPostContent(e.target.value)}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={isTextareaDisabled}
        />

        <div className="form">
          <div className="taggedUsers">
            <div className="addButtons">
              {/* <Button appearance="link" disabled={isUserTagDisabled}>Tag a user</Button> */}
              <Button
                appearance="link"
                disabled={isUserTagDisabled}
                onClick={() => setIsEmailModalVisible(true)}
              >
                Tag someone by email
              </Button>
              {/* <Button appearance="link" disabled={isUserTagDisabled}>Tag someone by phone number</Button>*/}
            </div>

            {userList.length > 0 && (
              <TagGroup style={{textAlign: 'center'}}>
                {userList.map(({label, key}) => (
                  <Tag key={key}>{label}</Tag>
                ))}
              </TagGroup>
            )}
          </div>

          <div>
            {submitRetryCount > 0 && itemErrorMessage && (
              <Message description={itemErrorMessage} type="warning" />
            )}
            <Button appearance="primary" loading={isItemProcessing} disabled={isSumbitDisabled} onClick={handleSubmit} block>Post</Button>
          </div>
        </div>
      </div>
    </div>
  );
};
