import React, { useState, createRef, useEffect } from 'react';
import { Modal, Button, Message, Form, FormGroup, ControlLabel, FormControl, Schema } from 'rsuite';
import { useSelector, useDispatch } from 'react-redux';
import { Selectors, Operations } from '@airstudio/state';
import { Bus, TrackEvent } from '@airstudio/state';

import './style.scss';
import {IUser} from '../../types';

export const Onboarding: React.FC = () => {

  const { isAuthenticated } = useSelector(Selectors.Session.state);
  const user = useSelector(Selectors.Me.get) as IUser;
  const {isProcessing, errorMessage} = useSelector(Selectors.Me.state);
  const updateUser = Operations.Me.update(useDispatch());
  const [formData, setFormData] = useState({firstname: '', lastname: ''});
  const formRef: any = createRef();

  useEffect(() => {
    if (!user.firstname || !user.lastname) {
      TrackEvent('onboarding.view');
    }
  }, [user.firstname, user.lastname]);

  const model = Schema.Model({
    firstname: Schema.Types.StringType()
      .minLength(3, 'First name must be at least 3 characters long')
      .maxLength(30, 'First name must be at most 30 characters long')
      .isRequired('This field is required.'),
    lastname: Schema.Types.StringType()
      .minLength(3, 'Last name must be at least 3 characters long')
      .maxLength(30, 'Last name must be at most 30 characters long')
      .isRequired('This field is required.'),
});

  const handleSubmit = async () => {

    TrackEvent('onboarding.submit');

    Bus.trigger(Bus.ON_ANALYTICS, {

    });

    if (!formRef.current.check()) {
      return;
    }

    const isSuccess = await updateUser({ id: user.id, ...formData });

    if (isSuccess) {
      TrackEvent('onboarding.success');
    }
  };

  if (isAuthenticated &&
      (!user.firstname || !user.lastname)
    ) {

    return (
      <div className="onboarding">
        <Modal size="xs" show backdrop="static" keyboard={false}>
          <Modal.Header>
            <h3>
              Welcome to Nijis
            </h3>
          </Modal.Header>
          <Modal.Body>
            <p style={{fontSize: 18, lineHeight: 1.75}}>
              Your community uses Nijis to share their gratefulness.
              And someone has a special message for you!
            </p>

            <p style={{fontSize: 16, marginTop: 40, marginBottom: 20}}>
              To view this message, complete your profile:
            </p>

            <Form
              onSubmit={handleSubmit}
              model={model}
              value={formData}
              onChange={(v) => setFormData(v as any)}
              ref={formRef}
            >
              <FormGroup>
                <ControlLabel>Your first name:</ControlLabel>
                <FormControl name="firstname" disabled={isProcessing} />
              </FormGroup>

              <FormGroup>
                <ControlLabel>Your last name:</ControlLabel>
                <FormControl name="lastname" disabled={isProcessing} />
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            {errorMessage && (
              <Message description={errorMessage} />
            )}
            <Button loading={isProcessing} appearance="primary" onClick={handleSubmit}>Save</Button>
          </Modal.Footer>
        </Modal>
      </div >
    );
  }

  return  <div/>;

};
