import {InitState, Extensions} from '@airstudio/state';
import {Amplitude, SplitIO, LogRocket, Sentry, GoogleAnalytics, Mixpanel} from '@airstudio/extensions';
import React from 'react';
import reactDom from 'react-dom';
import 'rsuite/dist/styles/rsuite-default.css';

import { Root } from './components/Root';
import { CustomAuth } from './extensions/CustomAuth';
import './index.scss';

Extensions.Register(new CustomAuth());
Extensions.Register(new Sentry());
Extensions.Register(new Amplitude());
Extensions.Register(new SplitIO());
Extensions.Register(new LogRocket());
Extensions.Register(new GoogleAnalytics());
Extensions.Register(new Mixpanel());

InitState();

reactDom.render(<Root />, document.getElementById('root'));
